<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }" />
  <!-- <div>{{ startTime }}</div> -->
</template>

<script>
import { getvrcounttrend } from "@/api/vrdatacount"
import * as echarts from "echarts"
import resize from "./mixins/resize"

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    id: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "200px",
    },
    height: {
      type: String,
      default: "200px",
    },
    startTime: {
      type: String,
      default: "chart",
    },
    endTime: {
      type: String,
      default: "",
    },
  },
  data () {
    return {
      chart: null,
      start_time: '',
      end_time: ''
    }
  },
  watch: {
    startTime: {
      handler (start_time) {
        this.start_time = start_time //newVal即是chartData
      },
      immediate: true,
      deep: true
    },
    endTime: {
      handler (end_time) {
        this.end_time = end_time //newVal即是chartData
        this.getvrcounttrend()
      },
      immediate: true,
      deep: true
    },
  },
  created () {
    //  this.getvrcounttrend();
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    getvrcounttrend () {
      let per = {
        vr_id: decodeURIComponent(this.$route.query.id),
        start_time: this.start_time,
        end_time: this.end_time
      }
      getvrcounttrend(per).then((response) => {
        if (response.code === 0) {
          if (response.data && response.data.length > 0) {
            let xdata = [], vr_count = []
            response.data.forEach(item => {
              xdata.push(item.date)
              vr_count.push(item.vr_count)
            })
            this.initChart(xdata, vr_count)
          }
        }
      }).catch((error) => {
        //console.log(error);
      })
    },
    initChart (xdata, vr_count) {
      this.chart = echarts.init(document.getElementById(this.id))
      var option
      option = {
        tooltip: {
          trigger: "axis",
        },
        // legend: {
        //   data: ["完成人次"],
        //   left: "10",
        //   top: "10",
        //   icon: "rect",
        // },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        // 坐标轴
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xdata
        },
        yAxis: {
          type: "value",
        },
        // 趋势图
        series: [
          {
            name: "完成人次",
            type: "line",
            // stack: "总量",
            data: vr_count,
          }
        ],
      }

      this.chart.setOption(option)
    },
  },
};
</script>
