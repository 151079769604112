<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import dayjs from "dayjs"
import { getvrtimetrend } from "@/api/vrdatacount"
import * as echarts from "echarts"
import resize from "./mixins/resize"

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart2",
    },
    id: {
      type: String,
      default: "chart2",
    },
    width: {
      type: String,
      default: "200px",
    },
    height: {
      type: String,
      default: "200px",
    },
    startTime1: {
      type: String,
      default: "chart",
    },
    endTime1: {
      type: String,
      default: "chart",
    },
  },
  watch: {
    startTime1: {
      handler (start_time) {
        // debugger
        this.start_time = start_time //newVal即是chartData
        // this.getvrtimetrend();
      },
      immediate: true,
      deep: true
    },
    endTime1: {
      handler (end_time) {
        // debugger
        this.end_time = end_time //newVal即是chartData
        this.getvrtimetrend()
      },
      immediate: true,
      deep: true
    },
  },
  data () {
    return {
      chart: null,
      start_time: '',
      end_time: ''
    }
  },
  // mounted() {
  //   this.getvrtimetrend();
  // },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    getvrtimetrend () {
      let params = {
        vr_id: decodeURIComponent(this.$route.query.id),
        start_time: this.start_time,
        end_time: this.end_time
      }
      getvrtimetrend(params)
        .then((response) => {
          //console.log(params);
          if (response.code === 0) {
            if (response.data && response.data.length > 0) {
              let xdata = [],
                ydata = []
              response.data.forEach((item) => {
                xdata.push(item.date)
                // dayjs.unix(item.sum_time).format("mm")
                ydata.push(item.sum_time)
              })
              this.initChart(xdata, ydata)
            }
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    initChart (xdata, ydata) {
      this.chart = echarts.init(document.getElementById(this.id))
      var option

      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xdata,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "访问时长(分钟)",
            type: "bar",
            barMaxWidth: "30",
            data: ydata,
          },
        ],
      }

      this.chart.setOption(option)
    },
  },
};
</script>
